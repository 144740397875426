import { ArrowRight } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { SwInternalLink } from './bedrock/navigation/SwInternalLink';

export const SwPageButtonLink: FC<{ pageName?: keyof typeof WEBSITE_MENU_ITEM; to?: string; name?: string }> = (
    props
) => {
    const { pageName, name, to } = props;
    const { t } = useTranslation();

    return (
        <SwInternalLink
            className={'page-link'}
            to={to ?? WEBSITE_MENU_ITEM[pageName]['link']}
            variant={'secondary'}
            withI18n={WEBSITE_MENU_ITEM[pageName]?.['withI18n'] ?? true}
        >
            <ArrowRight /> {name ?? t(WEBSITE_MENU_ITEM[pageName]['key'] as any)}
        </SwInternalLink>
    );
};
