import React from 'react';
import { styled } from '@mui/material';
import { grey6 } from '../bedrock/SwColors';

const StyledDivContainer = styled('div')`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${grey6};
`;

const StyledDiv = styled('div')`
    width: 100%;
`;

const SwWebsiteContainer: React.FunctionComponent<SwWebsiteContainerProps> = (props: SwWebsiteContainerProps) => {
    return (
        <StyledDivContainer>
            <StyledDiv>{props.children}</StyledDiv>
        </StyledDivContainer>
    );
};

interface SwWebsiteContainerProps {
    children: any;
}

export default SwWebsiteContainer;
