import { Box, BoxProps, styled, useTheme } from '@mui/material';
import { grey5 } from '../../../lib/components/bedrock/SwColors';

export type WebsiteSectionsLayoutProps = BoxProps & {
    gap?: number;
    thickness?: number;
    hasLineAtTheEnd?: boolean;
};

const WebsiteSectionsLayout = styled(Box)<WebsiteSectionsLayoutProps>`
    --_gap: ${({ gap }) => useTheme().spacing(gap ?? 6)};

    & > * {
        border-block-end: ${({ thickness }) => thickness ?? 1}px solid ${grey5};
        padding-block-end: var(--_gap);
    }

    & > *:not(:last-child) {
        margin-block-end: var(--_gap);
    }

    & > *:last-child {
        border-block-end: ${({ thickness, hasLineAtTheEnd }) =>
            !hasLineAtTheEnd ? 'none' : `${thickness ?? 1}px solid ${grey5}`};
        padding-block-end: ${({ hasLineAtTheEnd }) => (!hasLineAtTheEnd ? '0px' : `var(--_gap)`)};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        --_gap: ${({ theme }) => theme.spacing(4)};
    }
`;

export default WebsiteSectionsLayout;
