import { useCallback, useEffect } from 'react';
import { useLocalstorageState as useLocalstorage } from 'rooks';
import { isBrowser } from '../../app/common/helpers/gatsby';

export type UseLocalStorageStateArgs<T> = {
    key: string;
    initialValue: T;
};

type EventPayload<T> = {
    key: string;
    value: T;
};

export function useLocalStorageGlobalState<T = unknown>({
    key,
    initialValue
}: UseLocalStorageStateArgs<T>): [T, (newValue: T) => void, () => void] {
    const [item, setItem, removeItem] = useLocalstorage(key, initialValue);
    const eventKey = `storageChange:${key}`;

    const updateItem = useCallback(
        (newValue: T) => {
            if (!isBrowser()) {
                return;
            }

            setItem(newValue);
            const event = new CustomEvent(eventKey, { detail: { key: eventKey, value: newValue } });
            document.dispatchEvent(event);
        },
        [setItem, eventKey]
    );

    const syncItem = useCallback(
        (e: CustomEvent<EventPayload<T>>) => {
            if (e.detail.key === eventKey) {
                setItem(e.detail.value);
            }
        },
        [setItem, eventKey]
    );

    useEffect(() => {
        if (!isBrowser()) {
            return;
        }

        document.addEventListener(eventKey, syncItem);

        return () => document.removeEventListener(eventKey, syncItem);
    }, [eventKey, syncItem]);

    return [item, updateItem, removeItem];
}
