import { X } from '@phosphor-icons/react';
import React from 'react';
import { Box, useMediaQuery, useTheme, styled } from '@mui/material';
import SwContainer from '../../../../components/v1/SwContainer';
import { skyBlueLight } from '../SwColors';
import SwSpacer from '../layout/SwSpacer';

export const DEFAULT_BANNER_HEIGHT = 56;

const StyledBox = styled(Box)`
    top: 0px;
    position: sticky;
    display: flex;
    align-items: center;
`;

const StyledButtonBox = styled(Box)`
    cursor: pointer;
`;

const SwBanner: React.FunctionComponent<SwBannerProps> = (props) => {
    const { bgColor, onClick, onClose, ...rest } = props;

    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <StyledBox
            bgcolor={bgColor ?? skyBlueLight}
            minHeight={`${DEFAULT_BANNER_HEIGHT}px`}
            py={isSmallScreen ? 1 : 0}
            onClick={(event) => {
                onClick(event);
            }}
            {...rest}
        >
            <SwContainer height={'100%'} withMaxWidth={true}>
                <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    {props.children}
                    <SwSpacer direction={'horizontal'} spacing={2} />
                    <StyledButtonBox alignItems={'center'} display={'flex'} height={'100%'} pl={2}>
                        <X
                            aria-label={'Close banner'}
                            size={18}
                            onClick={(event) => {
                                event.stopPropagation();
                                onClose(event);
                            }}
                        />
                    </StyledButtonBox>
                </Box>
            </SwContainer>
        </StyledBox>
    );
};

interface SwBannerProps {
    bgColor?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onClose?: (event: React.MouseEvent<SVGSVGElement>) => void;
    children?: any;
}

export default SwBanner;
