import { hashString } from '../helpers/string';
import { useLocalStorageGlobalState } from './useLocalStorageGlobalState';

export const FLASH_ARTICLE_URL = 'https://www.sweep.net/get-in-touch';
export const defaultKey = hashString(FLASH_ARTICLE_URL).toString();

export function useFlashBanner(
    option: { key: string; initialValue?: boolean } = { key: defaultKey, initialValue: true }
) {
    return useLocalStorageGlobalState<boolean>(option as Required<{ key: string; initialValue?: boolean }>);
}
