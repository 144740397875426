import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwPageButtonLink } from '@lib/components/SwPageButtonLink';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { colors } from '@lib/components/bedrock/fundations';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { AspectRatios } from '@lib/enums/apectRatios';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const themeMap = {
    business: colors.crop[400],
    finance: colors.glacier[400]
};

const MainLandingPage = ({ data, pageContext }) => {
    const { title, subtitle, hero } = data.page;
    const [firstSection, secondSection, thirdSection] = data.page.sections;
    const { slug } = pageContext;
    const theme = useTheme();

    const { t } = useTranslation();

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={''}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <WebsiteSectionsLayout>
                        <SwContainer gap={32}>
                            <SwPaper bgcolor={colors.surface.subdued} height={'100%'} p={2} width={'100%'}>
                                <Box
                                    border={`1px solid ${colors.border}`}
                                    borderRadius={2}
                                    height={'100%'}
                                    overflow={'hidden'}
                                    position={'relative'}
                                >
                                    <Box
                                        bgcolor={'white'}
                                        left={0}
                                        maxWidth={500}
                                        p={3}
                                        position={{ xs: 'relative', sm: 'absolute' }}
                                        sx={{
                                            borderBottomRightRadius: 8,
                                            borderBottom: `1px solid ${colors.border}`,
                                            borderInlineEnd: `1px solid ${colors.border}`,
                                            [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
                                                borderInlineEnd: 0,
                                                borderBottomRightRadius: 0
                                            }
                                        }}
                                        top={0}
                                    >
                                        <SwTypography component={'h1'} variant={'h3'}>
                                            {title}
                                        </SwTypography>
                                        <SwTypography
                                            bold={true}
                                            color={colors.text.secondary}
                                            component={'p'}
                                            variant={'h3'}
                                        >
                                            {subtitle}
                                        </SwTypography>
                                    </Box>
                                    <Box
                                        bgcolor={themeMap[slug]}
                                        overflow={'hidden'}
                                        sx={{
                                            aspectRatio: AspectRatios.widescreen,
                                            [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
                                                aspectRatio: AspectRatios.landscape
                                            }
                                        }}
                                        width={'100%'}
                                    >
                                        {Boolean(hero) && (
                                            <GatsbyImage
                                                alt={hero.alt}
                                                image={hero.gatsbyImageData}
                                                objectFit={'cover'}
                                                style={{ height: '100%', width: '100%' }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </SwPaper>
                            <SwPaper overflow={'visible'}>
                                <Stack gap={2}>
                                    <SwTypography component={'h2'} variant={'h4'}>
                                        {t('we_support_organizations_like_yours')}
                                    </SwTypography>
                                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                                        {[...data.industryPages.nodes.filter((page) => page.slug.startsWith(slug))].map(
                                            (page, index) => (
                                                <SwPageButtonLink key={index} name={page.title} to={`/${page.slug}`} />
                                            )
                                        )}
                                    </Stack>
                                </Stack>
                            </SwPaper>
                            <SwCmsRenderer content={firstSection} />
                        </SwContainer>
                        <Stack spacing={4}>
                            <SwContainer gap={32}>
                                <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlockEnd: 32 }}>
                                    <SwTypography component={'h2'} maxWidth={430} variant={'h3'}>
                                        {t('org_already_using_sweep')}
                                    </SwTypography>
                                    <SwTypography
                                        bold={true}
                                        color={colors.text.secondary}
                                        component={'p'}
                                        variant={'h3'}
                                    >
                                        {t('building_cleaner_future_together')}
                                    </SwTypography>
                                </SwGrid>
                                <SwCmsRenderer content={[...secondSection.children].splice(0, 2)} />
                                <SwLine spacingBottom={0} spacingTop={0} />
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    columns={3}
                                    content={data.customerStories.nodes}
                                    renderItems={({ company, ...rest }) => (
                                        <SwRecordCard
                                            key={rest.id}
                                            meta={
                                                <GatsbyImage
                                                    alt={company.logo.alt}
                                                    image={company.logo.gatsbyImageData}
                                                    objectFit={'contain'}
                                                    style={{ height: '100%', width: '100%' }}
                                                />
                                            }
                                            record={{
                                                ...rest,
                                                company,
                                                coverImage: {
                                                    alt: company.images?.[0]?.alt,
                                                    gatsbyImageData: company.images?.[0]?.gatsbyImageData
                                                }
                                            }}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                            <SwContainer>
                                <SwLine spacingBottom={4} spacingTop={0} />
                                <SwCmsRenderer content={[...secondSection.children].splice(2)} />
                            </SwContainer>
                        </Stack>
                        <SwContainer>
                            <SwCmsRenderer content={thirdSection} />
                        </SwContainer>
                    </WebsiteSectionsLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getMainLandingPageInformation($locale: GraphCMS_Locale, $id: String, $stories: [String], $slug: String) {
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        customerStories: allGraphCmsCustomerStory(
            sort: { order: DESC, fields: published }
            limit: 3
            filter: { locale: { in: [$locale] }, actualLocale: { in: [$locale] }, company: { name: { in: $stories } } }
        ) {
            nodes {
                ...CustomerStoryCard_GraphCMS_CustomerStory
            }
        }
        enPage: graphCmsPage(slug: { eq: $slug }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        industryPages: allGraphCmsPage(
            filter: {
                locale: { eq: $locale }
                slug: {
                    in: [
                        "finance/banks"
                        "finance/private-markets"
                        "finance/asset-owners"
                        "finance/asset-managers"
                        "business/manufacturing"
                        "business/grocery"
                        "business/professional-services"
                        "business/healthcare"
                        "business/consumer-goods"
                        "business/media-and-telecoms"
                        "business/energy-utilities-oil-and-gas"
                        "business/retail"
                    ]
                }
            }
        ) {
            nodes {
                id
                slug
                title
            }
        }
        page: graphCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
            id
            remoteId
            title
            subtitle
            slug
            hero {
                alt
                gatsbyImageData(aspectRatio: 1.778, layout: FULL_WIDTH, placeholder: BLURRED)
            }
            seo {
                ...Essentials_GraphCMS_Seo
            }
            sections {
                ... on GraphCMS_Section {
                    id
                    __typename
                    target
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_EmphasizedText
                        ...Essentials_GraphCMS_MediaCard
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_CustomerLogo
                                        ...Essentials_GraphCMS_LogoCard
                                        ...Essentials_GraphCMS_CallToAction
                                        ...Essentials_GraphCMS_FeatureVideoCard
                                        ...Essentials_GraphCMS_CustomersCarousel
                                        ...Essentials_GraphCMS_Stat
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default MainLandingPage;
