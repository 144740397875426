import React from 'react';
import { styled } from '@mui/material';
import { navigate } from 'gatsby';
import { grey3, skyBlueStrong, white } from '@lib/components/bedrock/SwColors';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { zIndexes } from '@lib/enums/zIndexes';
import { useFlashBanner } from '@lib/hooks/useFlashBanner';
import SwChip from '../../../components/v1/SwChip';
import SwLink from '../../../components/v1/SwLink';
import SwBanner from '../../../lib/components/bedrock/banner/SwBanner';
import { isBrowser } from '../helpers/gatsby';

const StyledSwBanner = styled(SwBanner)`
    z-index: ${zIndexes.FLASH};
    position: relative;
`;

const FlashBanner: React.FunctionComponent<{
    banner: {
        id: string;
        text: string;
        linkText: string;
        target: {
            slug: string;
            category?: {
                usage: string;
            };
            __typename: string;
        };
    };
}> = ({ banner }) => {
    const slug =
        banner.target.__typename === 'GraphCMS_Article'
            ? `${WEBSITE_MENU_ITEM[banner.target.category?.usage.toLowerCase()].link}/${banner.target.slug}`
            : `/${banner.target.slug}`;

    const alreadyOnThePage = isBrowser()
        ? window.location.href === `https://${process.env.GATSBY_SWEEP_DOMAIN}${slug}`
        : true;

    const [showBanner, setShowBanner] = useFlashBanner({
        key: `flash-banner-${banner.id}`,
        initialValue: true
    });

    if (alreadyOnThePage || !showBanner) {
        return null;
    }

    return (
        <StyledSwBanner
            onClick={() => {
                setShowBanner(false);
                navigate(slug);
            }}
            onClose={() => setShowBanner(false)}
        >
            <SwFlexBox alignItems={'center'} gap={16}>
                <SwChip bgColor={skyBlueStrong} label={'New'} textColor={white} />
                <SwTypography variant={'body2'}>
                    {banner.text}{' '}
                    <SwLink
                        hovercolor={grey3}
                        href={slug}
                        noHoverUnderlined={false}
                        noNewTab={true}
                        underlined={true}
                        variant={'body2'}
                        onClick={(event) => {
                            event.stopPropagation();
                            setShowBanner(false);
                        }}
                    >
                        {banner.linkText} →
                    </SwLink>
                </SwTypography>
            </SwFlexBox>
        </StyledSwBanner>
    );
};

export default FlashBanner;
